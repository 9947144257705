import React, { useEffect, useState } from 'react';

import useSound from 'use-sound';

import { toAbsoluteUrl } from '../_ens/_helpers';

const FanfareButton = (props) => {
  const [playbackRate, setPlaybackRate] = React.useState(0.75);
  const [count, setCount] = React.useState(0);

  const [play, { stop }] = useSound(toAbsoluteUrl('/media/sounds/sound1.mp3'), {
    interrupt: true,
    onplay: () => {
      if (props.play == true) {
        setCount(1);
      }
    }
  });

  return <>{props.play == true && count == 0 ? play() : ''}</>;
};

export default FanfareButton;

// import React, { useEffect, useState } from 'react';

// import useSound from 'use-sound';

// import { toAbsoluteUrl } from '../_ens/_helpers';

// const FanfareButton = (props) => {
//   const [playbackRate, setPlaybackRate] = React.useState(0.75);
//   const [count, setCount] = React.useState(0);

//   const [play, { stop }] = useSound(toAbsoluteUrl('/media/sounds/sound1.mp3'), {
//     interrupt: true,
//     onload: () => {
//       if (props.play == true) {
//       }
//     }
//   });

//   useEffect(() => {
//     if (props.play == true) {
//       setTimeout(() => {
//         props.setPlaySound(false);
//         stop();
//       }, 100);
//     }
//   }, [props]);

//   console.log('props.play', props.play);
//   return <>{props.play == true ? play() : ''}</>;
// };

// export default FanfareButton;
