import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { FormattedMessage, injectIntl } from 'react-intl';
import PositionedModal from '../../../_ens/layout/components/modal/modal';
import { connect } from 'react-redux';
import * as common from '../../../redux/commonReducer';
import { bindActionCreators } from 'redux';
import {getAdminSettings, updateAppointment} from '../../modules/Admin/AdminActions';
import DeleteModal from '../../../_ens/layout/components/Confirmation';
import Select from "react-select";
// import { isAdmin } from '../../servics/CommonFunctions';
import { useHistory } from 'react-router-dom';
import {isAdmin} from "../../servics/CommonFunctions";
import {Button} from "react-bootstrap";
import moment from "moment";

function UserPopup(props) {
  const { intl } = props;
  const [geoRule, setGeoRule] = useState(false);
  const [noEnte, setNoEnte] = useState(false);
  const initialValues = props.apointmentModel;
  const [affiliatedOption] = useState(props.apointmentModel.affiliatedOption);
  const [aptStatus, setAptStatus] = useState(
    intl.formatMessage({
      id: 'CONFIRMATION'
    })
  );
  let history = useHistory();

  const onCallInterpreter = (videoCode) => {
    history.push('singlecall/',videoCode);
  };

  useEffect(() => {
    getAdminSettings().then(
      (res) => {
        setGeoRule(res.geoRule);
        setNoEnte(res.noEnte);
      },
      (err) => {}
    );
    if (props.apointmentModel.status === 1) {
      let string = intl.formatMessage({
        id: 'DASHBOARD.ACCEPTED'
      });
      setAptStatus(
        intl.formatMessage({
          id: 'CONFIRMATION'
        }) +
          ' | ' +
          string
      );
    }

    if (props.apointmentModel.status === 2) {
      let string = intl.formatMessage({
        id: 'DASHBOARD.PENDINGACCEPTANCE'
      });
      setAptStatus(
        intl.formatMessage({
          id: 'CONFIRMATION'
        }) +
          ' | ' +
          string
      );
    }
  }, [props.apointmentModel]);
  const [confirmDelete, setConfirmDelete] = useState(false);

  const AppointmentSchema = Yup.object().shape({
    interpreterId: Yup.string().required(
      intl.formatMessage({
        id: 'AUTH.VALIDATION.REQUIRED_FIELD'
      })
    )
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid';
    }

    /* if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }*/

    return '';
  };

  const downlodaFile = async (appointment_file) => {
        //fetch('https://ens-api.appdemoserver.com' + appointment_file, {
       //fetch('https://dev.solunicanet.it:4000' + appointment_file, {
       fetch('https://sportellosordita.com:4000' + appointment_file, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${props.auth.authToken}`
      }
    })
      .then((resp) => resp.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        // the filename you want
        a.download = appointment_file.replace('/appointment/', '');
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      });
  };

  const formik = useFormik({
    initialValues,
    validationSchema: AppointmentSchema,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      let datatoshare = {
        appointmentId: props.apointmentModel.id,
        interpreterId: values.interpreterId
      };

      /*assignAppointment(datatoshare).then(res=>{
            props.updateCurrentUser();
        })*/
    }
  });

  const handleRequest = (val) => {
    let datatosend = {
      reply: val,
      statusId: props.apointmentModel.id,
      appointmentId:
        props.apointmentModel.appointment && props.apointmentModel.appointment.id
          ? props.apointmentModel.appointment.id
          : ''
    };

    updateAppointment(datatosend).then((res) => {
      props.commonActions.showSnackBar(
        true,
        intl.formatMessage({
          id: 'DASHBOARD.INTERPRATOR_UPDATE_APPOINTMENT'
        })
      );
      props.onModelHide();
      if (props.updateCurrentUser) props.updateCurrentUser();
    });
  };

  //props.onModelHide

  return (
    <div>
      <DeleteModal
        open={confirmDelete}
        title={intl.formatMessage({
          id: 'CONFIRMATION'
        })}
        msg={intl.formatMessage({
          id: 'APPOINTMENT_DECLINE'
        })}
        handleCancel={() => setConfirmDelete(false)}
        cacelColor="btn btn-light btn-elevate"
        cancelText={intl.formatMessage({
          id: 'No'
        })}
        handleOk={() => handleRequest(3)}
        okColor="btn btn-delete btn-elevate"
        okText={intl.formatMessage({
          id: 'YES'
        })}
      ></DeleteModal>

      <PositionedModal
        center={true}
        show={props.modalShow}
        title={aptStatus}
        onHide={props.onModelHide}
        onSubmit={() => handleRequest(1)}
        updateText={
          props.apointmentModel.status != 1
            ? intl.formatMessage({
                id: 'ACCEPT'
              })
            : ''
        }
        CancelText={intl.formatMessage({
          id: 'CANCEL'
        })}
        deletebtn={() => setConfirmDelete(true)}
        deletetext={
          props.apointmentModel.isdeleted != 1
            ? intl.formatMessage({
                id: 'DECLINE'
              })
            : ''
        }
        deleteClass="btn btn-delete btn-elevate"
      >
        <form className="form fv-plugins-bootstrap fv-plugins-framework">
          <div className="form-group row">
            {/* First Name */}
            <div className="col-lg-6">
              <label className="col-form-label">
                <FormattedMessage id="DASHBOARD.DATE" />
              </label>
              <input
                readOnly={props.apointmentModel.id}
                placeholder="Date"
                type="date"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  'date'
                )}`}
                name="date"
                {...formik.getFieldProps('date')}
              />
              {formik.touched.date && formik.errors.date ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.date}</div>
                </div>
              ) : null}
            </div>

            <div className="col-lg-3">
              <label className="col-form-label">
                <FormattedMessage id="DASHBOARD.START_TIME" />
              </label>
              <input
                placeholder="Start Time"
                type="time"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  'startTime'
                )}`}
                name="startTime"
                readOnly={props.apointmentModel.id}
                {...formik.getFieldProps('startTime')}
              />
              {formik.touched.startTime && formik.errors.startTime ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.startTime}</div>
                </div>
              ) : null}
            </div>

            <div className="col-lg-3">
              <label className="col-form-label">
                <FormattedMessage id="DASHBOARD.END_TIME" />
              </label>
              <input
                placeholder="End Time"
                type="time"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  'endTime'
                )}`}
                name="endTime"
                readOnly={props.apointmentModel.id}
                {...formik.getFieldProps('endTime')}
              />
              {formik.touched.endTime && formik.errors.endTime ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.endTime}</div>
                </div>
              ) : null}
            </div>
          </div>

          <div className="form-group row">
            <div className="col-lg-12">
              <label className="col-form-label">
                <FormattedMessage id="DASHBOARD.TITLE" />
              </label>
              <input
                placeholder="Title"
                type="text"
                readOnly={props.apointmentModel.id}
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  'title'
                )}`}
                name="title"
                {...formik.getFieldProps('title')}
              />
              {formik.touched.title && formik.errors.title ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.title}</div>
                </div>
              ) : null}
            </div>
          </div>

          {!geoRule ? (<div className="form-group row">
            <div className="col-lg-12">
              <label className="col-form-label">
                <FormattedMessage id="DASHBOARD.ADDRESS" />
              </label>

              <input
                placeholder="Address"
                type="text"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  'place'
                )}`}
                readOnly={props.apointmentModel.id}
                name="place"
                {...formik.getFieldProps('place')}
              />
            </div>
          </div>
          ):(<div className={"form-group row " + ( noEnte ? 'display-none' : '') } >
          <div className="col-lg-12">
            <label className="col-form-label">
              <FormattedMessage id="DASHBOARD.AFFILIATED" />
            </label>

            <Select
              readonly
              options={affiliatedOption}
              name="affiliatedstructureId"
              value={affiliatedOption.filter((data) => data.value === formik.values.affiliatedstructureId)}
              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                'affiliatedstructureId'
              )}`}
              onChange={(e) => formik.setFieldValue(`affiliatedstructureId`, e.value)}
              isSearchable
            />

          </div>
        </div>)}

          <div className="form-group row">
            <div className="col-lg-12">
              <label className="col-form-label">
                <FormattedMessage id="DASHBOARD.DESCRIPTION" />
              </label>
              <textarea
                placeholder="description"
                type="text"
                rows="4"
                readOnly={props.apointmentModel.id}
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  'description'
                )}`}
                name="description"
                {...formik.getFieldProps('description')}
              />
              {formik.touched.description && formik.errors.description ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.description}</div>
                </div>
              ) : null}
            </div>
          </div>

          <div className="form-group row">
            <div className="col-lg-6">
              <label className="col-form-label">
                <FormattedMessage id="DASHBOARD.CUSTOMERNAME" />
              </label>
              <input
                placeholder="customerName"
                type="text"
                readOnly
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  'customerName'
                )}`}
                name="customerName"
                value={
                  props.apointmentModel.account.firstName +
                  ' ' +
                  props.apointmentModel.account.lastName
                }
              />
            </div>
            {props?.apointmentModel?.id && props.apointmentModel.appointment_file !== null ? (
              <div className="col-lg-6">
                <label className="col-form-label mr-5">
                  <FormattedMessage id="APPOINTMENT_FILE" />
                </label>
                <span className="d-block">
                  <a
                    onClick={() =>
                      downlodaFile(props.apointmentModel.appointment_file, props.apointmentModel)
                    }
                  >
                    <FormattedMessage id="PROFILE.VIE_PROOF" />{' '}
                  </a>
                </span>
              </div>
            ) : (
              ''
            )}
          </div>

          {(geoRule && !isAdmin() && props.apointmentModel.id && props.apointmentModel.videoCode !=="" && props.apointmentModel.status === 1 && moment(props.apointmentModel.date).isSame(moment(), 'day')) ?(
            <div className="form-group row">
              <div className="col-lg-12 text-center">
                <Button variant="danger" className="fnt-videocall" onClick={() => onCallInterpreter(props.apointmentModel.videoCode)}>
                  AVVIA VIDEOCHIAMATA
                </Button>
              </div>
            </div>
          ):('')}

        </form>
      </PositionedModal>
    </div>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth
});

const mapDispatchToEvents = (dispatch) => {
  return {
    commonActions: bindActionCreators(common.actions, dispatch)
  };
};

export default injectIntl(connect(mapStateToProps, mapDispatchToEvents)(UserPopup));
