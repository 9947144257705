import { Repository } from '../../../servics/httpsServics';
export const LOGIN_URL = 'authenticate';
export const REGISTER_URL = 'register';
export const REQUEST_PASSWORD_URL = 'forgot-password';
export const VERIFYEMAIL = 'verify-email';
export const VERIFYOTP = 'verify-otp';
export const RESENDOTP = 'send-otp';
export const RESETPASSWORD = 'reset-password';
export const SETPASSWORD = 'set-password';
export const SET_USER_WITHOUT_OTP_VERIFICATION_URL = 'set-user-without-otp';

export const CITIES = '/cities';
export const CITIESINTERPRETOR = '/cities/interpreter';

const controller = '/accounts/';

export function login(email, password) {
  return Repository.post(controller + LOGIN_URL, { email, password });
}

export function setUserWithoutOTPVerification(data) {
  return Repository.post(controller + SET_USER_WITHOUT_OTP_VERIFICATION_URL, { ...data });
}

export function register(data) {
  return Repository.post(controller + REGISTER_URL, data);
}

export function requestPassword(email) {
  return Repository.post(controller + REQUEST_PASSWORD_URL, { email });
}

export function resetPassword(data) {
  return Repository.post(controller + RESETPASSWORD, data);
}

// set interpreter password
export function setPassword(data) {
  return Repository.post(controller + SETPASSWORD, data);
}

export function verifyEmail(token) {
  return Repository.post(controller + VERIFYEMAIL, { token });
}

export function verifyOtp(data) {
  return Repository.post(controller + VERIFYOTP, data);
}

export function resendOtp(email) {
  return Repository.post(controller + RESENDOTP, { email });
}

export function getCityList() {
  // Authorization head should be fulfilled in interceptor.
  return Repository.get(CITIES);
}
export function getCityListInterpretor() {
  // Authorization head should be fulfilled in interceptor.
  return Repository.get(CITIESINTERPRETOR);
}
