import React, {lazy} from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import Peer from 'peerjs';
import { connect } from 'react-redux';
import { Prompt } from 'react-router-dom';

import CallComponent from './../../modules/InterpreterSingleCall/ui';
import {
  getVideoAppointment,
  endUserCall
} from '../../modules/InterpreterSingleCall/Action';
import UserCallComponent from './../../modules/UserCall/ui';
import {Modal, Button, Spinner} from 'react-bootstrap';
import * as moment from 'moment';
import { toAbsoluteUrl } from '../../../_ens/_helpers';
import * as videoCall from '../../../redux/videoCallReducer';
import { bindActionCreators } from 'redux';
import * as Yup from 'yup';
import { WebSocketContext } from '../../../_ens/layout/components/Layout';
const RoomList = lazy(() => import('./../../pages/ChatMessage/RoomList'));

class VideoCallSinglePage extends React.Component {
  static contextType = WebSocketContext;
  constructor(props) {
    super(props);
    this.state = {
      current_state: 'connecting',
      videoCode: null,
      peer: null,
      peer_id: null,
      from_user: null,
      current_call_peer_id: null,
      videoContainer: [],
      showDisconnected: false,
      audioEnabled: true,
      isFullScreen: false,
      videoCallToggle: false,
      interpreter_id: null,
      user_id: null,
      alertShow: false,
      buttonEventDisable: false,
      senderName: '',
      receiverName: ''
    };
    this.socket = null;
    this.interval = null;

  }

  async componentDidMount() {
    this.socket = this.context;
    await this.connectSocket();
    const statePage  = this.props.location;
    const videoCodeIn=statePage.state;

    const temp_peer = await this.initializePeerConnection();
    const { firstName, lastName } = this.props.auth.user;

    this.setState({ peer: temp_peer, senderName: `${firstName} ${lastName}`,videoCode:videoCodeIn }, () => {
      this.initializePeersEvents();
      if (this.props.auth.user.role === 'User') {
        this.listenToUserEvent();
      } else {
        this.listenToInterpreterEvent();
      }
    });

    this.checkForUsers();
    this.interval = setInterval(async () => {
      this.checkForUsers();
      // }, 10000);
    }, 2000);

    window.addEventListener('online', this.handleConnectionChange);
    window.addEventListener('offline', this.handleConnectionChange);
  }

  addUnloadPrompt() {
    window.addEventListener('beforeunload', this.keepOnPage);
    window.addEventListener('unload', this.handleEndConcert);
  }

  removeUnloadPrompt() {
    window.removeEventListener('beforeunload', this.keepOnPage);
    window.removeEventListener('unload', this.handleEndConcert);
  }

  //  try for browser tab close handle in ongoing call
  keepOnPage = (e) => {
    e.preventDefault();
    // var message = 'You want to leave this page?';

    e.returnValue = '';
    // return message;
  };

  //  try for browser tab close handle in ongoing call
  handleEndConcert = async () => {
    const data = {
      peer_id: this.state.current_call_peer_id
        ? this.state.current_call_peer_id
        : this.state.peer !== null
        ? this.state.peer._id
        : null,
      close_tab: true,
      videoCode:this.state.videoCode
    };
    let headers = {
      type: 'application/json',
      Authorization: `Bearer ${this.props.auth.authToken}`
    };
    let blob = new Blob([JSON.stringify(data)], headers);

    // https://ens-api.appdemoserver.com
    //navigator.sendBeacon(`https://ens-api.appdemoserver.com/user-calls/end-call-forcefully`, blob);
    //navigator.sendBeacon(`https://csblombardia.ens.it:4000/user-calls/end-call-forcefully`, blob);
     //navigator.sendBeacon(`https://dev.solunicanet.it:4000/user-single-calls/end-call-forcefully`, blob);
     navigator.sendBeacon(`https://sportellosordita.com:4000/user-single-calls/end-call-forcefully`, blob);
  };

  handleConnectionChange = () => {
    const condition = navigator.onLine ? 'online' : 'offline';
    if (condition === 'online') {
    } else {
      this.setState({ showDisconnected: true });
      return;
    }
  };

  handleClose() {
    this.setState({ showDisconnected: false }, () => this.closeRoom());
  }


  componentWillUnmount() {
    // window.removeEventListener('beforeunload', this.keepOnPage);
    // window.removeEventListener('unload', this.handleEndConcert);

    // for if call end with Abruptly when tab change
    this.socket.emit('closedAbruptlySingleCall', {
      peer_id: this.state.current_call_peer_id
        ? this.state.current_call_peer_id
        : this.state.peer !== null
        ? this.state.peer._id
        : null,
      videoCode:this.state.videoCode
    });


    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
    this.endCall();
  }

  connectSocket = async () => {
    // this.socket = await openSocket('https://ens-api.appdemoserver.com', {
    // this.socket = await openSocket('https://csblombardia.ens.it:4000', {
    // this.socket = await openSocket('https://localhost:4000', {
    //   forceNew: false,
    //   transports: ['websocket'],
    //   query: {
    //     token: this.props.auth.authToken
    //   }
    // });
    // this.socket = socketObject;
  };

  serveUser = async () => {
    const appointment = await getVideoAppointment(this.state.videoCode);
    if (appointment) {
      this.socket.emit('initialize-single-call', { user_id: appointment['userId'], videoCode: this.state.videoCode });
      this.setState({
        current_state: 'starting_call',
        from_user: appointment['userId'],
        interpreter_id: appointment['interpreterId'],
        user_id: appointment['userId'],
      });
    }
  };

  listenToInterpreterEvent = () => {
    this.socket.on('interpreter-single-event', (data) => {
      switch (data['type']) {
        case 'USER_ALREADY_ON_CALL':
          if(data['videoCode']===this.state.videoCode) {
            this.setState(
              {
                from_user: null
              },
              () => {
                this.serveUser();
              }
            );
          }
          break;
        case 'USER_AVAILBALE':
          if(data['videoCode']===this.state.videoCode) {
            this.setState({
              current_call_peer_id: data['peer_id'],
              videoCallToggle: true,
              receiverName: `${data.senderData.firstName} ${data.senderData.lastName}`
            });
            this.inializeCallFromInterpreter(data);
          }
          break;
        case 'END_CALL':
          if(data['videoCode']===this.state.videoCode) {
            this.setState({
              videoCallToggle: false
            });
            this.removeUnloadPrompt();
            this.gracefulCallEnd();
          }
          break;
        case 'PEER_DESTROY':
          if(data['videoCode']===this.state.videoCode) {
            this.setState({
              videoCallToggle: false
            });
            this.gracefulCallEnd();
          }
          break;
        default:
          break;
      }
    });
  };

  listenToUserEvent = () => {
    this.socket.on('user-single-event', (data) => {
      switch (data['type']) {
        case 'CALL_INITIATED':
          if(data.videoCode===this.state.videoCode) {
            if ( this.state.current_state !== 'connected' &&
              this.state.current_state !== 'starting_call' ) {
              this.setState( {
                  current_state: 'starting_call',
                  from_user: data['from_user'],
                  receiverName: `${data.senderData.firstName} ${data.senderData.lastName}`,
                  videoCallToggle: true
                },
                () => {
                  this.socket.emit('user-single-available', {
                    interpreter: data['from_user'],
                    peer_id: this.state.peer_id,
                    videoCode: this.state.videoCode,
                  });
                  this.inializeCallFromUser();
                }
              );
            } else {
              this.socket.emit('user-already-on-single-call',{videoCode: this.state.videoCode});
            }
          }
          break;
        case 'END_CALL':
          if(data['videoCode']===this.state.videoCode) {
            this.setState({
              videoCallToggle: false
            });
            this.removeUnloadPrompt();
            this.gracefulCallEnd();
          }
          break;
        case 'PEER_DESTROY':
          if(data['videoCode']===this.state.videoCode) {
            this.setState({
              videoCallToggle: false
            });
            this.gracefulCallEnd();
          }
          break;
        default:
          break;
      }
    });
  };

  checkForUsers = async () => {

    const temp_count = await getVideoAppointment(this.state.videoCode);

    const temp_state = {
      user_id:temp_count.userId,
      interpreter_id:temp_count.interpreterId,
    };

    if ( Object.keys(temp_count).length > 0 &&
      this.props.auth.user.role === 'Interpreter' &&
      !this.state.videoCallToggle && temp_count.userAvailable) {
      temp_state['current_state'] = 'ready_to_accept';
    } else {
      if (this.state.current_state !== 'connected') {
        if (this.state.current_state !== 'starting_call') temp_state['current_state'] = 'waiting';
      }
      // change when no user available
    }

    this.setState({ ...temp_state });
  };

  initializePeerConnection = () => {
    console.log(Math.floor(100000 + Math.random() * 900000));
    return new Peer(`${Math.floor(100000 + Math.random() * 900000)}`, {
      host: 'sportellosordita.com',
      port: 9000,
      secure: true,
      debug: 3,
      config: {
        iceServers: [
          { urls: ['stun:stun.solunicanet.it:5349'] },
          {
            urls: [
              'turn:turn.solunicanet.it:3478?transport=udp',
              'turn:turn.solunicanet.it:3478?transport=tcp',
              'turns:turn.solunicanet.it:5349?transport=tcp'
            ],
            credential: 'T34mC0mp4ny!!!Turn2021',
            username: 'solunicanet'
          }
        ]
      }
    });
  };

  initializePeersEvents = () => {
    this.state.peer.on('open', (id) => {
      console.log('peer opened', this.state.peer);
      this.setState({ peer_id: id, current_state: 'waiting' });
      this.socket.emit('initialize-single-connection',{ videoCode:this.state.videoCode });

    });
    this.state.peer.on('error', (err) => {
      console.log('peer connection error', err);
    });
    this.state.peer.on('disconnected', (err) => {
      // this.socket.emit('peer-destroyed', { peer_id: this.state.peer_id });
      console.log('disconnected');

      // this.state.peer.reconnect();
    });

    this.state.peer.on('close', (err) => {
      console.log('peer closed', this.state.peer);
      this.setState({ peer: null });
      this.socket.emit('peer-single-destroyed', { user_id: this.state.from_user,videoCode:this.state.videoCode });
      // this.state.peer.reconnect();
    });
  };

  inializeCallFromInterpreter = (availableUserData = null) => {
    this.getVideoAudioStream().then(
      (stream) => {
        if (this.state.peer) {
          console.log('call started');
          const temp_call = this.state.peer.call(this.state.current_call_peer_id, stream);
          // change to set peer id
          this.props.videoCallActions.setPeerId(this.state.current_call_peer_id);
          if (temp_call) {
            // create user call record
            if (availableUserData) {

              const data = {
                peer_id: availableUserData['peer_id'],
                interpreter_id: this.props.auth.user.id,
                user_id: availableUserData['user_id'],
                videoCode:availableUserData['videoCode']
              };
             // initializeUserCall(data);


            }
            // end
            temp_call.on('stream', (remoteStream) => {
              this.setState({
                current_state: 'connected',
                alertShow: true
              });

              const local_ele = document.getElementById('local-video-container');
              this.createVideo(
                local_ele,
                stream,
                this.state.peer_id,
                'local',
                this.state.senderName
              );
              const remote_ele = document.getElementById('remote-video-container');
              this.createVideo(
                remote_ele,
                remoteStream,
                this.state.current_call_peer_id,
                'remote',
                this.state.receiverName
              );
              this.addUnloadPrompt();
            });
          }
        }
      },
      function(err) {
        console.log('Failed to get local stream', err);
      }
    );
  };

  inializeCallFromUser = () => {
    this.state.peer.on('call', (call) => {
      this.getVideoAudioStream().then((stream) => {
        call.answer(stream);
        call.on(
          'stream',
          (remoteStream) => {
            this.setState({
              current_state: 'connected'
            });
            // Show stream in some video/canvas element.
            const local_ele = document.getElementById('local-video-container');
            this.createVideo(local_ele, stream, this.state.peer_id, 'local', this.state.senderName);
            const remote_ele = document.getElementById('remote-video-container');
            this.createVideo(
              remote_ele,
              remoteStream,
              this.state.current_call_peer_id,
              'remote',
              this.state.receiverName
            );
            this.addUnloadPrompt();
          },
          function(err) {
            console.log('Failed to get local stream', err);
          }
        );
      });
    });
  };

  getVideoAudioStream = (video = true, audio = true) => {
    // let quality = this.settings.params?.quality;
    let quality = 12;
    if (quality) quality = parseInt(quality);
    const myNavigator =
      navigator.mediaDevices.getUserMedia ||
      navigator.mediaDevices.webkitGetUserMedia ||
      navigator.mozGetUserMedia ||
      navigator.mediaDevices.msGetUserMedia;
    return myNavigator({
      video: video
        ? {
            frameRate: quality ? quality : 12,
            noiseSuppression: true,
            width: { min: 640, ideal: 1280, max: 1280 },
            height: { min: 480, ideal: 720, max: 720 }
          }
        : false,
      audio: audio
    });
  };

  createVideo = (element, stream, id, type, name) => {
    if (!this.state.videoContainer[id]) {
      const temp_video_container = this.state.videoContainer;
      temp_video_container[id] = { id: id, stream: stream, type };
      this.setState({ videoContainer: temp_video_container });
      const video = document.createElement('video');

      video.srcObject = stream;
      if (this.state.peer_id === id) {
        video.muted = 'muted';
      }
      video.id = this.state.peer_id;
      video.width = 600;
      video.height = 300;
      video.autoplay = true;

      if (type !== 'local') {
        const para = document.createElement('p');
        para.innerText = `name : ${name}`;
        para.style.color = 'white';
        para.style.fontWeight = '600';
        para.style.textTransform = 'uppercase';
        para.style.marginBottom = '5px';
        para.style.textAlign = 'center';
        para.id = `p_${this.state.peer_id}`;
        element.appendChild(para);
      }
      element.appendChild(video);
    }
  };

  removeVideo = (id) => {
    const temp_video_container = this.state.videoContainer;
    delete temp_video_container[id];
    const video = document.getElementById(id);
    const para = document.getElementById(`p_${id}`);
    if (video) video.remove();
    if (para) para.remove();
    this.setState({
      videoContainer: temp_video_container
    });
  };

  onMute = () => {
    Object.keys(this.state.videoContainer).forEach((vid, index) => {
      if (this.state.videoContainer[vid]['type'] === 'local') {
        this.state.videoContainer[vid].stream.getAudioTracks()[0].enabled = !this.state
          .audioEnabled;
        // console.log(this.state.videoContainer[vid].stream.getAudioTracks()[0]);
      }
    });
    this.setState({ audioEnabled: !this.state.audioEnabled });
  };

  closeRoom = () => {
    if (this.props.auth.user.role === 'Interpreter') {
      this.props.history.push('/Interpreter/calendar');
    } else {
      this.props.history.push('/calendar');
    }
  };


  endCall = () => {
    this.removeUnloadPrompt();
    this.socket.emit('end-single-call', {
      user_id: this.state.from_user,
      videoCode:this.state.videoCode
    });
    this.gracefulCallEnd();
  };

  destoryConnection = () => {
    Object.keys(this.state.videoContainer).forEach((vid) => {
      const myMediaTracks = this.state.videoContainer[vid].stream.getTracks();
      console.log('myMediaTracks', myMediaTracks);
      myMediaTracks.forEach((track) => {
        track.stop();
      });
    });
  };

  gracefulCallEnd = async () => {
    document.getElementById('kt_content').classList.remove('black-color');
    this.state.peer_id ?? this.removeVideo(this.state.peer_id);
    this.state.current_call_peer_id ?? this.removeVideo(this.state.current_call_peer_id);
    this.destoryConnection();
    if (this.state.current_call_peer_id) {
      this.props.videoCallActions.setPeerId(null);
      endUserCall({
        peer_id: this.state.current_call_peer_id,
        end_ts: moment().unix()
      });
    }
    if (this.state.peer) {
      this.state.peer.disconnect();
      this.state.peer.destroy();
    }
    if (this.props.auth.user.role === 'User') {
      this.setState({
        current_state: 'waiting',
        current_call_peer_id: null,
        peer_id: null,
        peer: null,
        from_user: null,
        isFullScreen: false,
        audioEnabled: true,
        videoCode:null,
        interpreter_id:null,
        user_id:null
      });
      window.location.href = window.location.origin + '/calendar';
    }

    if (this.props.auth.user.role === 'Interpreter') {
      window.location.href = window.location.origin + '/Interpreter/calendar';

    }

    };

  onChangeScreen = () => {
    this.setState({ isFullScreen: !this.state.isFullScreen });
  };


  render() {
    let validationSchema = Yup.object().shape({
      title: Yup.string().required(
        this.props.intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD'
        })
      )
    });

    if (this.state.current_state !== 'connected') {
      document.getElementById('kt_content').classList.remove('black-color');
    } else {
      document.getElementById('kt_content').classList.add('black-color');
    }

    return (
      <>
        <Prompt when={false} message={() => 'Sei Sicuro di voler lasciare la videochiamata?'} />

        <div>
          <div id="full-screen-wrap"></div>

          <div className="row">
          <div className="col-8">
            {this.state.current_state !== 'connected' ? (
              <>
                {this.props.auth.user.role === 'Interpreter' ? (
                  <CallComponent
                    serveUser={this.serveUser}
                    closeRoom={this.closeRoom}
                    current_state={this.state.current_state}
                  />
                ) : (
                  <UserCallComponent
                    current_state={this.state.current_state}
                    closeRoom={this.closeRoom}
                  />
                )}
              </>
            ) : (
              <div className="video-call-main">
                <div className={`video-call-box ${this.state.isFullScreen ? 'fs' : ''}`}>
                  <div className="screen-control" onClick={this.onChangeScreen}>
                    {this.state.isFullScreen ? (
                      <img alt="mute" src={toAbsoluteUrl('/media/svg/icons/Call/minimize.svg')} />
                    ) : (
                      <img
                        alt="un-mute"
                        src={toAbsoluteUrl('/media/svg/icons/Call/fullscreen.svg')}
                      />
                    )}
                  </div>

                  <div id="remote-video-container"></div>

                  <div className="end-video-call-container">
                    <span onClick={this.endCall}>
                      <svg
                        height="512"
                        viewBox="0 0 128 128"
                        width="512"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g id="Circle_Grid" data-name="Circle Grid">
                          <circle cx="64" cy="64" fill="#ef5261" r="64" />
                        </g>
                        <g id="icon">
                          <path
                            d="m57.831 70.1c8.79 8.79 17.405 12.356 20.508 9.253l4.261-4.26a7.516 7.516 0 0 1 10.629 0l9.566 9.566a7.516 7.516 0 0 1 0 10.629l-7.453 7.453c-7.042 7.042-27.87-2.358-47.832-22.319-9.976-9.981-16.519-19.382-20.748-28.222s-5.086-16.091-1.567-19.61l7.453-7.453a7.516 7.516 0 0 1 10.629 0l9.566 9.563a7.516 7.516 0 0 1 0 10.629l-4.264 4.271c-3.103 3.1.462 11.714 9.252 20.5z"
                            fill="#eeefee"
                          />
                        </g>
                      </svg>
                    </span>
                    <span className="mute" onClick={this.onMute}>
                      {!this.state.audioEnabled ? (
                        <img
                          alt="mute"
                          src={toAbsoluteUrl('/media/svg/icons/Call/mute-microphone.svg')}
                        />
                      ) : (
                        <img
                          alt="un-mute"
                          src={toAbsoluteUrl('/media/svg/icons/Call/microphone.svg')}
                        />
                      )}
                    </span>
                    {this.state.audioEnabled}
                  </div>
                </div>
                <div className={`video-call-list ${this.state.isFullScreen ? 'fs' : ''}`}>
                  <div id="local-video-container" className="local-video-container"></div>
                </div>
              </div>
            )}

          </div>

            <div className="col-4">
             <RoomList props={{videoCode:this.state.videoCode}} />
            </div>

          </div>

        </div>

        <Modal
          show={this.state.showDisconnected}
          onHide={() => this.handleClose()}
          dialogClassName="modal-dialog-centered"
        >
          <Modal.Body>
            <p>Connesione Persa!</p>
            <p>Errore di connessione</p>
            <div className="text-right">
              <Button variant="primary" onClick={() => this.handleClose()}>
                OK
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth
});

const mapDispatchToEvents = (dispatch) => {
  return {
    videoCallActions: bindActionCreators(videoCall.actions, dispatch)
  };
};

export default injectIntl(connect(mapStateToProps, mapDispatchToEvents)(VideoCallSinglePage));
