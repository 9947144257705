import React, { useState, useEffect, useContext } from 'react';
import { Nav, Tab, Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import SVG from 'react-inlinesvg';
import { FormattedMessage, injectIntl } from 'react-intl';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { connect } from 'react-redux';
import moment from 'moment';

import { toAbsoluteUrl } from '../../../_ens/_helpers';
import { useHistory } from 'react-router-dom';
import { DropdownTopbarItemToggler } from '../../../_ens/_partials/dropdowns';
import { getParentRoute } from '../../servics/CommonFunctions';
import { messaging } from '../../../firebase';
import { WebSocketContext } from '../../../_ens/layout/components/Layout';
import { getRooms } from './Action';
import {getMsgCount} from "../../modules/Admin/AdminActions";

const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false
};

function ChatNotificationTopBar(props) {
  let history = useHistory();
  let redirectUrl = getParentRoute();
  var timer=0;


  const ws = useContext(WebSocketContext);
  const [key, setKey] = useState('Alerts');
  const bgImage = toAbsoluteUrl('/media/misc/bg-1.jpg');

  const [messageList, setMessageList] = useState([]);
  const [totalMsgCount,setTotalMsgCount] = useState(null)

  useEffect(() => {
    // firebase stop
    // if (messaging) {
    //   messaging.onMessage((payload) => {
    //     if (payload && Object.keys(payload).length > 0) {
    //       const room = payload?.data?.['gcm.notification.room']
    //         ? JSON.parse(payload?.data?.['gcm.notification.room'])
    //         : {};
    //       const appointment = payload?.data?.['gcm.notification.appointment']
    //         ? JSON.parse(payload?.data?.['gcm.notification.appointment'])
    //         : {};
    //       let messageArray = {
    //         appointment: appointment,
    //         room: room,
    //         notification: payload?.notification,
    //         senderRole: payload?.data?.['gcm.notification.role']
    //       };
    //       let authId = props?.auth?.user?.id ? props.auth.user.id : null;
    //       let senderId = room?.sender_id ? room.sender_id : null;
    //       if (authId !== null && senderId !== null && authId !== senderId) {
    //         setMessageList((messageList) => [...messageList, messageArray]);
    //       }
    //     }
    //   });
    // }
    // if (props?.socket?.socket !== undefined) {

    startTimer()
    getPendingMsg();


    if (ws) {
      const currentDate = moment(new Date()).format('YYYY-MM-DD');

      getRooms({ final_date: currentDate })
        .then((res) => {
          if (res && res.length > 0) {
            res.forEach((e) => {
              ws.emit('joinChatRoom', e.room_id);
            });
          }
        })
        .catch((e) => console.log(e));
      ws.on('topBarMessageNotification', (message) => {
        if (message && Object.keys(message).length > 0) {
          const room = message?.messageRoomData ? message.messageRoomData : {};
          const appointment = message?.jsonAppointmentData ? message.jsonAppointmentData : {};
          let messageArray = {
            appointment: appointment,
            room: room,
            notification: message?.messageNotification,
            senderRole: message?.senderUser?.role,
            senderUser: message?.senderUser
          };
          let authId = props?.auth?.user?.id ? props.auth.user.id : null;
          let senderId = room?.sender_id ? room.sender_id : null;

          // if (authId !== null && senderId !== null && authId !== senderId) {
          //   setMessageList((messageList) => [...messageList, messageArray]);
          // }
          let msgId = room?.id ? room.id : null;
          if (authId !== null && senderId !== null && msgId !== null && authId !== senderId) {
            setMessageList((messageList) => {
              const exist = messageList.find((msg) => msg.room.id === msgId);
              if (!exist) {
                return [...messageList, messageArray];
              }
              return messageList;
            });
          }
        }
      });
    }
    // }


    return ()=>{
      clearInterval(timer)
    }
  }, [ws]);

  const startTimer = ()=> {
    timer = setInterval(getPendingMsg, 120000);
  }

  const getPendingMsg = () =>{

    getMsgCount({id:props.auth?.user?.id}).then(res=>{
      setTotalMsgCount(res.count_msg)
    },err=>{
    })
  }

  const handleClick = (data) => {
    // when one hour left for appointment
    // if (Object.keys(data).length > 0) {
    //   const messageArray = messageList;
    //   const resFilter = messageArray.filter((e) => e.room.room_id !== data.room.room_id);
    //   setMessageList(resFilter);
    //   const finalDateTime =
    //     data.appointment[0].final_date + ' ' + data.appointment[0].final_start_time;
    //   const now = moment(new Date());
    //   const end = moment(finalDateTime);
    //   const dayDiff = moment().diff(data.appointment[0].final_date, 'days');
    //   const duration = moment.duration(end.diff(now));
    //   const hours = duration.hours();
    //   const minutes = duration.minutes();
    //   const seconds = duration.seconds();
    //   let inputShow = false;
    //   if (dayDiff === 0 && (hours < 1 || hours < -23)) {
    //     inputShow = true;
    //   } else if (dayDiff === 0 && hours === 1 && minutes === 0 && seconds === 0) {
    //     inputShow = true;
    //   }
    //   history.push({
    //     pathname: `${redirectUrl}chatMessage`,
    //     state: {
    //       detail: {
    //         room_id: data.room.room_id,
    //         title: data.appointment[0].title,
    //         date: data.appointment[0].final_date,
    //         start_time: data.appointment[0].final_start_time,
    //         role: data.senderRole,
    //         toggle_input: inputShow
    //       }
    //     }
    //   });
    // }
    if (Object.keys(data).length > 0) {
      const messageArray = messageList;
      const resFilter = messageArray.filter((e) => e.room.room_id !== data.room.room_id);
      setMessageList(resFilter);

      const finalDateTime =
        data.appointment[0].final_date + ' ' + data.appointment[0].final_start_time;
      const now = moment(new Date());
      const end = moment(finalDateTime);

      const dayDiff = moment().diff(data.appointment[0].final_date, 'days');

      const duration = moment.duration(end.diff(now));

      let inputShow = false;
      if (dayDiff === 0 || dayDiff < 0) {
        inputShow = true;
      }
      history.push({
        pathname: `${redirectUrl}chatMessage`,
        state: {
          detail: {
            room_id: data.room.room_id,
            title: data.appointment[0].title,
            date: data.appointment[0].final_date,
            start_time: data.appointment[0].final_start_time,
            role: data.senderRole,
            toggle_input: inputShow
          }
        }
      });
    }
  };
  return (
    <>


      {(props.auth.user.role === 'User') ?

        <div className="dropdown" onClick={() =>
          history.push('/chatMessageWithAdmin')
        }>
          <div className="topbar-item">
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id="user-notification-tooltip">
                  <FormattedMessage id="NOTIFICATION.NOTIFICATION_HOVER_ADMIN"/>
                </Tooltip>
              }
            >
              <div
                className="btn btn-icon btn-clean btn-lg mr-1 pulse pulse-primary"
                id="kt_quick_notifications_toggle"
              >
            <span className="svg-icon svg-icon-xl svg-icon-primary">
              <SVG src={toAbsoluteUrl('/media/svg/icons/Communication/Chat4.svg')}/>
              {totalMsgCount > 0 ? <>{totalMsgCount}</> : null}
            </span>
                <span className="pulse-ring"></span>
                <span className="pulse-ring"/>
              </div>
            </OverlayTrigger>
          </div>
        </div>
        :null
      }



      <Dropdown drop="down" alignRight>
        <Dropdown.Toggle as={DropdownTopbarItemToggler} id="kt_quick_notifications_toggle">
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip id="CHAT_NOTIFICATION">
                <FormattedMessage id="CHAT_NOTIFICATION" />
              </Tooltip>
            }
          >
            <div
              className="btn btn-icon btn-clean btn-lg mr-1 pulse pulse-primary"
              id="kt_quick_notifications_toggle"
            >
              <span className="svg-icon svg-icon-xl svg-icon-primary msg-notification">
                <SVG src={toAbsoluteUrl('/media/svg/icons/Communication/Chat6.svg')} />{' '}
                {messageList && messageList.length > 0 ? (
                  <div className="notification-count">{messageList.length}</div>
                ) : (
                  <div className="notification-count">0</div>
                )}
              </span>
              <span className="pulse-ring"></span>
              <span className="pulse-ring" />
            </div>
          </OverlayTrigger>
        </Dropdown.Toggle>

        <Dropdown.Menu className="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg">
          <form>
            {/** Head */}
            <div
              className="d-flex flex-column pt-12 bgi-size-cover bgi-no-repeat rounded-top"
              style={{ backgroundImage: `url(${bgImage})` }}
            >
              <h4 className="d-flex flex-center rounded-top">
                <span className="text-white">
                  <FormattedMessage id="CHAT_NOTIFICATION_TITLE" />
                </span>
                <span className="btn btn-text btn-success btn-sm font-weight-bold btn-font-md ml-2">
                  {messageList && messageList.length > 0 ? <>{messageList.length}</> : <>0</>}

                  {'  '}
                  <FormattedMessage id="CHAT_NOTIFICATION_NEW" />
                </span>
              </h4>

              <Tab.Container defaultActiveKey={key}>
                <Nav
                  as="ul"
                  className="nav nav-bold nav-tabs nav-tabs-line nav-tabs-line-3x nav-tabs-line-transparent-white nav-tabs-line-active-border-success mt-3 px-8"
                  onSelect={(_key) => setKey(_key)}
                >
                  <Nav.Item className="nav-item" as="li">
                    <Nav.Link
                      eventKey="Alerts"
                      className={`nav-link show ${key === 'Alerts' ? 'active' : ''}`}
                    >
                      <FormattedMessage id="CHAT_NOTIFICATION_ALERTS" />
                    </Nav.Link>
                  </Nav.Item>
                </Nav>

                <Tab.Content className="tab-content">
                  <Tab.Pane eventKey="Alerts" className="p-8">
                    <PerfectScrollbar
                      options={perfectScrollbarOptions}
                      className="scroll pr-7 mr-n7"
                      style={{ maxHeight: '300px', position: 'relative' }}
                    >
                      {messageList && messageList.length > 0 ? (
                        <>
                          {messageList.map((data) => {
                            return (
                              <div
                                onClick={() => handleClick(data)}
                                key={Math.random()}
                                className="d-flex align-items-center mb-6"
                              >
                                <div className="d-flex flex-column font-weight-bold">
                                  <div className="text-dark text-hover-primary mb-1 font-size-lg">
                                    {data.appointment[0].title}
                                    <div>
                                      <span className="mb-1 text-muted">
                                        <FormattedMessage id="CHAT_DATE_LABEL" />:{' '}
                                        {data.appointment[0].final_date}
                                      </span>
                                      {'  '}
                                      <span className="mb-1 text-muted">
                                        <FormattedMessage id="CHAT_TIME_LABEL" />:{' '}
                                        {data.appointment[0].final_start_time}
                                      </span>
                                    </div>
                                  </div>
                                  <span className="text-muted">
                                    <FormattedMessage id="TOP_BAR_MESSAGE_BY" />:{' '}
                                    {data?.senderUser?.firstName} {data?.senderUser?.lastName}
                                  </span>
                                  <span className="text-muted">
                                    <FormattedMessage id="TOP_BAR_MESSAGE" />: {data.notification}
                                  </span>
                                </div>
                              </div>
                            );
                          })}
                        </>
                      ) : (
                        <FormattedMessage id="NO_CHAT_NOTIFICATION" />
                      )}{' '}
                    </PerfectScrollbar>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
          </form>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth
});

export default injectIntl(connect(mapStateToProps)(ChatNotificationTopBar));
