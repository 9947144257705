import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';

function ContactModel(props) {
  useEffect(() => {}, [props]);

  return (
    <Modal
      centered={props.center}
      size="lg"
      show={props.show}
      onHide={props.onHide}
      backdrop="static"
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">Contatti</Modal.Title>
      </Modal.Header>
      <Modal.Body>

        <div className="contact-modal">
          <div>
            <div>
              <div>
                <div><h3><span >EMAIL DEDICATA AL PROGETTO</span>
                </h3>
                  <p className="red-title"><a href={"mailto:comunicazioni@sportellosordita.com"}>comunicazioni@sportellosordita.com</a></p></div>
              </div>
            </div>
          </div>
          <div>
            <div>
              <div>
                <div><h3 className="h3-style"><span>Ente Nazionale Sordi Onlus </span>
                </h3>
                  <p className="text"><span><em>Sezione Provinciale di Trento </em></span></p>
                  <p className="text"><span>Via Piave, 106 - 38122 - TRENTO (TN)</span></p>
                  <p className="text"><span>Telefono: <a href={"tel:+390461235329 "} target="_blank" rel="noopener">0461/235329 </a></span></p>
                  <p className="text"><span>Cellulare: <a href={"tel:+393756297841"} target="_blank" rel="noopener">375/6297841</a></span></p>
                  <p className="text"><span>Email: <a href={"mailto:trento@ens.it"} target="_blank" rel="noopener">trento@ens.it</a></span></p>
                  <p className="text"><span>PEC: <a href={"mailto:trento@pec.ens.it"} target="_blank" rel="noopener">trento@pec.ens.it</a></span></p>
                  <p className="text"><span>Sito Web: <a href="https://www.ens.it /" target="_blank" rel="noopener">https://www.ens.it /</a></span></p>
                </div>
              </div>
            </div>
          </div>
        </div>


      </Modal.Body>
      <Modal.Footer>
        <button type="button" onClick={props.onHide} className="btn btn-light btn-elevate">
          <FormattedMessage id="CANCEL" />
        </button>

        <> </>
      </Modal.Footer>
    </Modal>
  );
}

export default injectIntl(ContactModel);
