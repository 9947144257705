import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import {toAbsoluteUrl} from "../../../../_ens/_helpers";

function IphoneModel(props) {
  useEffect(() => {}, [props]);

  return (
    <Modal
      centered={props.center}
      size="lg"
      show={props.show}
      onHide={props.onHide}
      backdrop="static"
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">AVVISO</Modal.Title>
      </Modal.Header>
      <Modal.Body>

        <div className="contact-modal">
          <div>
            <div>
              <div>
                <div><h3><span >&Egrave;  disponibile un'app dedicata per il tuo dispositivo</span>
                </h3>
                  <p className="red-title mt-8 text-center"><a href={"https://apps.apple.com/bf/app/sportello-sordit%C3%A0/id1626487821"}><img
                    alt="Logo"
                    className=""
                    style={{ width: '200px' }}
                    src={toAbsoluteUrl('/media/logos/appstore.png')}
                  /></a></p></div>
              </div>
            </div>
          </div>
        </div>


      </Modal.Body>
      <Modal.Footer>
        <button type="button" onClick={props.onHide} className="btn btn-light btn-elevate">
          <FormattedMessage id="CANCEL" />
        </button>

        <> </>
      </Modal.Footer>
    </Modal>
  );
}

export default injectIntl(IphoneModel);
