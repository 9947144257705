import React, { useMemo, useEffect, useState } from 'react';
import objectPath from 'object-path';
import { connect } from 'react-redux';
// LayoutContext
import { useHtmlClassService } from '../_core/EnsLayout';
// Import Layout components
import Header from './header/Header';
import { HeaderMobile } from './header-mobile/HeaderMobile';
import { Aside } from './aside/Aside';
import Footer from './footer/Footer';
import { LayoutInit } from './LayoutInit';
import { SubHeader } from './subheader/SubHeader';
import openSocket from 'socket.io-client';
import { getAminChatIds } from '../../../app/modules/Admin/AdminActions';
import { bindActionCreators } from 'redux';
import * as setSettingData from '../../../redux/settingReducer';
import { PinDropSharp } from '@material-ui/icons';

const WebSocketContext = React.createContext(null);

export { WebSocketContext };

function Layout({ children, auth, setSettingActions }) {
  const uiService = useHtmlClassService();

  // Layout settings (cssClasses/cssAttributes)
  const layoutProps = useMemo(() => {
    return {
      layoutConfig: uiService.config,
      selfLayout: objectPath.get(uiService.config, 'self.layout'),
      asideDisplay: objectPath.get(uiService.config, 'aside.self.display'),
      subheaderDisplay: objectPath.get(uiService.config, 'subheader.display'),
      desktopHeaderDisplay: objectPath.get(uiService.config, 'header.self.fixed.desktop'),
      contentCssClasses: uiService.getClasses('content', true),
      contentContainerClasses: uiService.getClasses('content_container', true),
      contentExtended: objectPath.get(uiService.config, 'content.extended')
    };
  }, [uiService]);
  const [socketObject, setSocketObject] = useState(null);

  const connectSocket = async () => {
    // this.socket = await openSocket('https://ens-api.appdemoserver.com', {
    // this.socket = await openSocket('https://csblombardia.ens.it:4000', {

    try {
      //const res = await openSocket('https://csblombardia.ens.it:4000', {
       //const res = await openSocket('https://dev.solunicanet.it:4000', {
       const res = await openSocket('https://sportellosordita.com:4000', {
      //const res = await openSocket('https://ens-api.appdemoserver.com', {
        forceNew: false,
        transports: ['websocket'],
        query: {
          token: auth.authToken
        }
      });
      if (Object.keys(res).length > 0) {
        setSocketObject(res);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getSettingData = async () => {
    try {
      const res = await getAminChatIds();
      if (
        res &&
        Object.keys(res).length > 0 &&
        res.hasOwnProperty('chat_admin') &&
        res.chat_admin
      ) {
        const adminsIds = res.chat_admin.split(',').map((e) => parseInt(e));
        setSettingActions.setSettingData({ chat_admin: adminsIds });
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getSettingData();
    if (auth.authToken) {
      connectSocket();
    }
  }, [auth.authToken]);

  return layoutProps.selfLayout !== 'blank' ? (
    <>
      <WebSocketContext.Provider value={socketObject}>
        {/*begin::Main*/}

        <HeaderMobile />
        <div className="d-flex flex-column flex-root">
          {/*begin::Page*/}
          <div className="d-flex flex-row flex-column-fluid page">
            {layoutProps.asideDisplay && <Aside />}
            {/*begin::Wrapper*/}
            <div
              className="d-flex flex-column flex-row-fluid wrapper"
              style={{ background: '#ffffff' }}
              id="kt_wrapper"
            >
              <Header />
              {/*begin::Content*/}
              {/* add class for two button in dashboard  */}
              <div
                id="kt_content"
                className={`content ${layoutProps.contentCssClasses} d-flex flex-column flex-column-fluid position-relative`}
              >
                {layoutProps.subheaderDisplay && <SubHeader />}
                {/*begin::Entry*/}
                {!layoutProps.contentExtended && (
                  <div className="d-flex flex-column-fluid">
                    {/*begin::Container*/}
                    <div className={layoutProps.contentContainerClasses}>{children}</div>
                    {/*end::Container*/}
                  </div>
                )}

                {layoutProps.contentExtended && { children }}
                {/*end::Entry*/}
              </div>
              {/*end::Content*/}
              <Footer />
            </div>
            {/*end::Wrapper*/}
          </div>
          {/*end::Page*/}
        </div>
        {/*end::Main*/}
      </WebSocketContext.Provider>
      <LayoutInit />
    </>
  ) : (
    // BLANK LAYOUT
    <div className="d-flex flex-column flex-root">{children}</div>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth
});
const mapDispatchToEvents = (dispatch) => {
  return {
    setSettingActions: bindActionCreators(setSettingData.actions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToEvents)(Layout);
