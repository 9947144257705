import React, { useEffect, useState } from 'react';
import { toAbsoluteUrl } from '../../../_ens/_helpers';
import SVG from 'react-inlinesvg';

import FanfareButton from '../../sound-demo';

function CallComponent(props) {

  return (
    <div className="video_call_main_wrapper">
      {props.current_state === 'ready_to_accept' ? (
        <div className="video_call_inner vc_serve_next_user">
          <div className="vc_next_user_wrap">
            <div className="vc_next_user_icon">
              {/* <img src="assets/users_solid.svg" alt="" /> */}
              <SVG src={toAbsoluteUrl('/media/svg/icons/User/users_solid.svg')} />
            </div>
            <div className="vc_waiting_btn global_btn">
              <button onClick={props.serveUser}>Servi l'utente</button>
              {/* <FanfareButton play={true} setPlaySound={setPlaySound} /> */}
              <FanfareButton play={true} />
            </div>
          </div>
        </div>
      ) : null}
      {props.current_state !== 'ready_to_accept' ? (
        <div className="video_call_inner vc_waiting_user">
          <div className="vc_waiting_user_wrap">
            <div className="vc_waiting_animation">
              <div className="vc_waiting_main_circle"></div>
              <div className="vc_circle_wave wave_1"></div>
              <div className="vc_circle_wave wave_2"></div>
              <div className="vc_circle_wave wave_3"></div>
              <div className="vc_circle_wave wave_4"></div>
            </div>
            <div className="vc_waiting_text">
              {props.current_state === 'connecting' || props.current_state === 'starting_call' ? (
                <p>In connessione</p>
              ) : null}
              {props.current_state === 'waiting' ? <p>In attesa dell'utente</p> : null}
            </div>
            <div className="vc_waiting_btn global_btn">
              {props.current_state === 'waiting' ? (
                <button onClick={props.closeRoom}>Termina Chiamata</button>
              ) : null}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default CallComponent;
