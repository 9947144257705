import React, { useEffect, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import {
  checkUserPermision,
  AllPermisions,
  isAdmin,
  isInterpreter,
  isUser,
  isAdminVerified
} from '../servics/CommonFunctions';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
  getUserList,
  getAvailbility,
  getUserAppointment,
  getAllInterprator,
  getUserAppointmentById,
  getInterpratorAppointmentById,
  getInterpratorAvailbilityById,
  getAffiliatedStructureList,
} from '../modules/Admin/AdminActions';
import SchedulePopup from './Popup/Schedule';
import ApointmentPopup from './Popup/BookApointment';
import AcceptPopup from './Popup/UpdateAppointment';
import { useFormik } from 'formik';
import store from '../../redux/store';
import { toAbsoluteUrl } from '../../_ens/_helpers';
import SVG from 'react-inlinesvg';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as common from '../../redux/commonReducer';
import Select from 'react-select';

require('moment/locale/it.js');
const localizer = momentLocalizer(moment);

function DashboardPage(props) {
  const { intl } = props;
  // change for calendar
  const today = new Date();

  let user = store.getState().auth.user;
  const [modalShow, setModelShow] = useState(false);
  const [event, setEvent] = useState([]);
  const [acceptPopup, setacceptPopup] = useState(false);
  const [interprator, setInterprator] = useState([]);
  const [users, setusers] = useState([]);
  const [adminType, setAdminType] = useState(0);
  const [currentView, setCurrentView] = useState('work_week');
  const [externalaptbtn, setexternalaptbtn] = useState(false);
  const [externalschbtn, setexternalschbtn] = useState(false);

  const [interpreterOption, setInterpreterOption] = useState([]);
  const [userOption, setUserOption] = useState([]);
  const [affiliatedOption, setAffiliatedOption] = useState([]);

  const initialValues = {
    userType: '',
    user: '',
    interpratorId: ''
  };

  let availbilityObj = {
    interpreterID: '',
    time: '',
    endTime: '',
    date: '',
    id: '',
    repeatDays: '',
    dateUntil: '',
    day0: false,
    day1: false,
    day2: false,
    day3: false,
    day4: false,
    day5: false,
    day6: false,
    allchecked: false
  };
  let apointmentObj = {
    userId: '',
    startTime: '',
    endTime: '',
    date: '',
    title: '',
    description: '',
    place: '',
    mode: 'inPerson',
    acceptTerms: false
  };

  let userTypes = [
    {
      label: intl.formatMessage({
        id: 'DASHBOARD_SELECT_USER_TYPE'
      }),
      value: '',
      disabled: true
    },
    {
      label: intl.formatMessage({
        id: 'DASHBOARD.USER'
      }),
      value: 1
    },
    {
      label: intl.formatMessage({
        id: 'DASHBOARD.INTERPRATOR'
      }),
      value: 2
    }
  ];

  const [scheduleobj, setscheduleobj] = useState(availbilityObj);
  const [selectedUser, setSelectedUser] = useState(null);
  const [apointmentModel, setApointment] = useState(apointmentObj);
  let mixedArray = [];

  useEffect(() => {

    if (isAdmin()) {
      getUserList('Interpreter').then((res) => {
        let interpreterOption1 = [];
        interpreterOption1.push({
          label: intl.formatMessage({
            id: 'DASHBOARD_SELECT_INTERPRATOR'
          }),
          value: ''
        });
        res.map((e) =>
          interpreterOption1.push({ label: `${e.firstName + ' ' + e.lastName}`, value: e.id })
        );
        setInterpreterOption(interpreterOption1);
        setInterprator(res);
      });
      getUserList('User').then((res) => {
        // setusers(res);
        let tempArray = [];
        let userOption1 = [];
        userOption1.push({
          label: intl.formatMessage({
            id: 'DASHBOARD_SELECT_USER'
          }),
          value: ''
        });
        res.forEach((user) => {
          if (user.adminVerified === 1 || user.adminVerified === '1') {
            tempArray.push(user);
            userOption1.push({ label: `${user.firstName + ' ' + user.lastName}`, value: user.id });
          }
        });
        setUserOption(userOption1);
        setusers(tempArray);
      });
    }

    getAffiliatedStructureList().then((res) => {
      let affOption1 = [];
      affOption1.push({
        label: intl.formatMessage({
          id: 'DASHBOARD_SELECT_AFFILIATED'
        }),
        value: ''
      });
      res.forEach((aff) => {
        affOption1.push({ label: aff.name, value: aff.id });
      });
      setAffiliatedOption(affOption1);
    });
    getIntailData('work_week');


  }, []);

  const getIntailData = (view) => {
    if (isInterpreter()) {
      getAvailbility().then((res) => {
        formatAvailbilityData(res);
        getUserAppointment().then((res) => {
          formatInterAppointmentData(res);
        });
      });
    }
    if (isUser()) {
      getUserAppointment().then((res) => {
        formatAppointmentData(res, view);
        getAllInterprator().then((res) => {
          let uniqueRes = [...new Map(res.map((item) => [item['date'], item])).values()];
          formatAvailbilityData(uniqueRes, view);
        });
      });
    }

    if (isAdmin()) {
      mixedArray.push([]);
      getAllInterprator().then((res) => {
        let uniqueRes = [...new Map(res.map((item) => [item['date'], item])).values()];
        formatAvailbilityData(uniqueRes, view);
      });
    }
  };

  const getAdminUserAppointmentById = (id) => {
    event.length = 0;
    setSelectedUser(id);
    setAdminType(1);
    getUserAppointmentById(id).then((res) => {
      mixedArray.push([]);
      formatAppointmentData(res);
    });
  };

  const getAdminInterAppointmentById = (id) => {
    event.length = 0;
    setAdminType(2);
    setSelectedUser(id);
    getInterpratorAvailbilityById(id).then((res) => {
      formatAvailbilityData(res);
      getInterpratorAppointmentById(id).then((res) => {
        formatInterAppointmentData(res);
      });
    });
  };

  // change for column for show final date and final_start_time and final_end_time
  const formatInterAppointmentData = (data) => {
    let tempArray = [];
    data.forEach((key) => {
      let tempobj = {
        start: new Date(key.appointment.final_date + 'T' + key.appointment.final_start_time),
        end: new Date(key.appointment.final_date + 'T' + key.appointment.final_end_time),
        title: key.appointment.title,
        appointmentobj: key.appointment,
        status: key.status,
        type: 'appointment',
        id: key.id
      };
      tempArray.push(tempobj);
    });
    mixedArray.push(tempArray);
    if (mixedArray.length == 2) {
      setEventData(mixedArray[0], mixedArray[1]);
    }
  };

  // change for column for show final date and final_start_time and final_end_time
  const formatAppointmentData = (data, view = 'work_week') => {
    let tempArray = [];
    data.forEach((key) => {
      if (key.final_date && key.final_start_time && key.appointmentStatuses.length > 0) {
        let tempobj = {
          start: new Date(key.final_date + 'T' + key.final_start_time),
          end: new Date(key.final_date + 'T' + key.final_end_time),
          title: key.title,
          status: key.appointmentStatuses[0].status,
          appointmentobj: key
        };
        tempArray.push(tempobj);
      }
    });
    mixedArray.push(tempArray);
    if (mixedArray.length == 2) {
      setEventData(mixedArray[0], mixedArray[1], view);
    }
  };

  const setEventData = (array1 = [], array2 = [], view = 'work_week') => {
    let tempArray = [];
    array1.forEach((element) => {
      tempArray.push(element);
    });
    array2.forEach((element) => {
      tempArray.push(element);
    });
    formatdataForMonthView(tempArray, view);
  };

  const getHtml = (view) => {
    return (
      <div className="Interpretor-available">
        {view == 'month' ? (
          <SVG src={toAbsoluteUrl('/media/svg/icons/Navigation/Check.svg')} />
        ) : (
          ''
        )}
      </div>
    );
  };

  const formatAvailbilityData = (data, view = 'work_week') => {
    let tempArray = [];
    data.forEach((key) => {
      let tempobj = {
        start: new Date(key.date + 'T' + key.time),
        end: new Date(key.date + 'T' + key.endTime),
        title: (isUser() || isAdmin())
          ? getHtml('work_week')
          : intl.formatMessage({
              id: 'AVAILABLE'
            }),
        type: 'Interprator',
        availbilty: key
      };
      tempArray.push(tempobj);
    });

    mixedArray.push(tempArray);
    if (mixedArray.length == 2) {
      setEventData(mixedArray[0], mixedArray[1], view);
    }
  };

  const formatDate = (date) => {
    let tempDate = new Date(date);
    let month = tempDate.getMonth() + 1;
    if (month < 10) month = '0' + month;

    let currentDate = tempDate.getDate();
    if (currentDate < 10) currentDate = '0' + currentDate;
    return tempDate.getFullYear() + '-' + month + '-' + currentDate;
  };

  const handleSelect = (e) => {
    if (currentView !== 'month' && e.start < new Date()) {
      props.commonActions.showSnackBar(
        true,
        intl.formatMessage({
          id: 'DASHBOARD_PAST_DATE'
        }),
        'error'
      );
      return;
    }
    if (currentView === 'month') {
      let month = new Date().getMonth() + 1;
      if (
        new Date(e.start) <
        new Date(new Date().getFullYear() + '/' + month + '/' + new Date().getDate())
      ) {
        props.commonActions.showSnackBar(
          true,
          intl.formatMessage({
            id: 'DASHBOARD_PAST_DATE'
          }),
          'error'
        );
        return;
      }
    }
    if ((isAdmin() && adminType == 0) || (isAdmin() && !selectedUser)) {
      props.commonActions.showSnackBar(
        true,
        intl.formatMessage({
          id: 'DASHBOARD_USER_NOT_SELECTED'
        }),
        'error'
      );
      return;
    }

    let startTime = '';
    let endTime = '';
    if (e.slots.length > 1) {
      if (e.slots[0].getHours() < 10) {
        startTime = '0' + e.slots[0].getHours();
      } else {
        startTime = e.slots[0].getHours();
      }
      if (e.slots[0].getMinutes() < 10) {
        startTime = startTime + ':0' + e.slots[0].getMinutes();
      } else {
        startTime = startTime + ':' + e.slots[0].getMinutes();
      }

      if (e.slots[e.slots.length - 1].getHours() < 10) {
        endTime = '0' + e.slots[e.slots.length - 1].getHours();
      } else {
        endTime = e.slots[e.slots.length - 1].getHours();
      }
      if (e.slots[e.slots.length - 1].getMinutes() < 10) {
        endTime = endTime + ':0' + e.slots[e.slots.length - 1].getMinutes();
      } else {
        endTime = endTime + ':' + e.slots[e.slots.length - 1].getMinutes();
      }
    }

    if (checkUserPermision(AllPermisions.ADDAPOINTMENT) || (isAdmin() && adminType == 1)) {
      if (isAdmin()) {
        setApointment({
          ...apointmentObj,
          userList: users,
          affiliatedOption: affiliatedOption,
          date: formatDate(e.start),
          userId: isAdmin() ? selectedUser : user.id,
          interpratorList: interprator,
          selectedInterprator: '',
          startTime: startTime,
          endTime: endTime
        });
      }
      else {
        setApointment({
          ...apointmentObj,
          date: formatDate(e.start),
          affiliatedOption: affiliatedOption,
          userId: isAdmin() ? selectedUser : user.id,
          startTime: startTime,
          endTime: endTime
        });
      }
      setTimeout(() => {
        setModelShow(true);
      }, 200);
    } else if (checkUserPermision(AllPermisions.ADDAVAILBILITY) || (isAdmin() && adminType == 2)) {
      setscheduleobj({
        ...availbilityObj,
        date: formatDate(e.start),
        affiliatedOption: affiliatedOption,
        interpreterID: isAdmin() ? selectedUser : user.id,
        time: startTime,
        endTime: endTime
      });
      setTimeout(() => {
        setModelShow(true);
      }, 200);
    }
  };

  const createappointment = () => {

    setexternalaptbtn(true);
    if (isAdmin()) {
      setApointment({
        ...apointmentObj,
        userList: users,
        affiliatedOption: affiliatedOption,
        date: formatDate(new Date()),
        userId: '',
        interpratorList: interprator,
        selectedInterprator: '',
        startTime: '',
        endTime: ''
      });
    } else {
      setApointment({
        ...apointmentObj,
        affiliatedOption: affiliatedOption,
        date: formatDate(new Date()),
        userId: user.id,
        startTime: '',
        endTime: ''
      });
    }

    setTimeout(() => {
      setModelShow(true);
    }, 200);
  };

  const createschedule = () => {
    setexternalschbtn(true);
    setscheduleobj({
      ...availbilityObj,
      date: formatDate(new Date()),
      interpreterID: isAdmin() ? '' : user.id,
      time: '',
      endTime: '',
      interpratorList: interprator
    });
    setTimeout(() => {
      setModelShow(true);
    }, 200);
  };

  // change for final_date and final_start_time and final_end_time
  const onEventSelect = (e) => {
    if (checkUserPermision(AllPermisions.ADDAPOINTMENT) || (isAdmin() && adminType == 1)) {
      // if( e.type=="Interprator")
      // return;
      //setApointment({...e.appointmentobj,startTime:formatTime(e.appointmentobj.startTime),endTime:formatTime(e.appointmentobj.endTime)})
      if (isAdmin()) {
        setApointment({
          ...e.appointmentobj,
          date: e.appointmentobj.final_date,
          startTime: e.appointmentobj.final_start_time,
          endTime: e.appointmentobj.final_end_time,
          userList: users,
          affiliatedOption: affiliatedOption,
          interpratorList: interprator,
          selectedInterprator:
            e.appointmentobj.appointmentStatuses[0].interpreterID != 0
              ? e.appointmentobj.appointmentStatuses[0].interpreterID
              : ''
        });
      } else {
        setApointment({
          ...e.appointmentobj,
          date: e.appointmentobj.final_date,
          startTime: e.appointmentobj.final_start_time,
          endTime: e.appointmentobj.final_end_time,
          affiliatedOption: affiliatedOption,
          interpratorList: [],
          userList: []
        });
      }
    }
    else {
      if (e.type == 'appointment') {
        setacceptPopup(true);
        // setApointment({...e.appointmentobj,startTime:formatTime(e.appointmentobj.startTime),endTime:formatTime(e.appointmentobj.endTime),id:e.id,status:e.status})
        setApointment({
          ...e.appointmentobj,
          id: e.id,
          status: e.status,
          affiliatedOption: affiliatedOption,
          isdeleted: e.appointmentobj.is_deleted
        });
      } else {
        setacceptPopup(false);
        //  setscheduleobj({...e.availbilty,time:formatTime(e.availbilty.time),endTime:formatTime(e.availbilty.endTime),reoccurring:0})
        setscheduleobj({ ...e.availbilty, reoccurring: 0 });
      }
    }
    setTimeout(() => {
      setModelShow(true);
    }, 200);
  };

  const onModelHide = (view) => {
    setacceptPopup(false);
    setexternalaptbtn(false);
    setexternalschbtn(false);
    mixedArray.length = 0;
    setModelShow(false);

    if (isUser() || isInterpreter()) {
      event.length = 0;
      getIntailData(view);
    }

    if (isAdmin() && selectedUser) {
      if (adminType == 1) {
        getAdminUserAppointmentById(selectedUser);
      } else {
        getAdminInterAppointmentById(selectedUser);
      }
    }
  };

  const formatdataForMonthView = (res, view = 'work_week') => {
    let tempEvents = [];
    res.forEach((element) => {
      if (element.type == 'Interprator') {
        let tempobj = {
          start: new Date(element.availbilty.date + 'T' + element.availbilty.time),
          end: new Date(element.availbilty.date + 'T' + element.availbilty.endTime),
          title: (isUser())
            ? getHtml(view)
            : intl.formatMessage({
                id: 'AVAILABLE'
              }),
          type: 'Interprator',
          availbilty: element.availbilty
        };
        tempEvents.push(tempobj);
      }
      else {
        tempEvents.push(element);
      }
    });
    setEvent(tempEvents);
  };

  const formik = useFormik({
    initialValues,
    onSubmit: (values, { setSubmitting }) => {}
  });

  const removeFilter = () => {
    setAdminType(0);
    setSelectedUser(null);
    formik.setValues({ ...formik.values, userType: '', user: '', interpratorId: '' });
    event.length = 0;
  };

  const changeUserSelect = () => {
    setAdminType(0);
    setSelectedUser(null);
    event.length = 0;
    // formik.setValues({ ...formik.values, user: '', interpratorId: '' });
  };

  const formats = {
    eventTimeRangeFormat: () => {
      return '';
    }
  };

  return (
    <div>
      {isAdmin() || isUser() ? (
        <div className="dashboard_legend">
          <div style={{ position: 'fixed', right: '0', top: '70px', zIndex: '99' }}>
            <div className="form-group row">
              {isAdmin() || isUser() ? (
                <button
                  type="button"
                  onClick={createappointment}
                  style={{ marginRight: '15px', background: '#eb457b', color: '#fff' }}
                  className="btn btn-light btn-elevate"
                >
                  <SVG src={toAbsoluteUrl('/media/svg/icons/Navigation/Plus.svg')} />
                  <FormattedMessage id="DASHBOARD.ADD_APPOINTMENT" />
                </button>
              ) : null}

              {isAdmin() || isInterpreter() ? (
                <button
                  type="button"
                  onClick={createschedule}
                  style={{ marginRight: '45px', background: '#687cfc', color: '#fff' }}
                  className="btn btn-light btn-elevate"
                >
                  <SVG src={toAbsoluteUrl('/media/svg/icons/Navigation/Plus.svg')} />
                  <FormattedMessage id="DASHBOARD.ADD_SCHEDULE" />
                </button>
              ) : null}
            </div>
          </div>
          <div className="mr-8">
            <span
              className="dashboard_legend_color mr-3"
              style={{ backgroundColor: 'lightgreen' }}
            ></span>
            <FormattedMessage id="DASHBOARD.ACCEPTED" />
          </div>

          <div className="mr-8">
            <span
              className="dashboard_legend_color mr-3"
              style={{ backgroundColor: '#c5dd0d' }}
            ></span>
            <FormattedMessage id="DASHBOARD.INTERPRATORPENDING" />
          </div>

          <div className="mr-8">
            <span className="dashboard_legend_color mr-3" style={{ backgroundColor: 'red' }}></span>
            <FormattedMessage id="DASHBOARD.ADMINPENDING" />
          </div>

          <div className="mr-8">
            <span className="dashboard_legend_color mr-3" style={{ backgroundColor: 'lightgray' }}></span>
            <FormattedMessage id="DASHBOARD.DELETED" />
          </div>

        </div>
      ) : null}

      <div className="row">
        {isAdmin() || isAdminVerified() ? (
          <div style={{ width: '100%' }}>
            <div style={{ display: 'inline-flex' }}>
              {isAdmin() ? (
                <div style={{ display: 'inline-flex' }}>
                  <div className="form-group row">
                    <label className="ml-3 mr-4 col-form-label">
                      <FormattedMessage id="DASHBOARD.USER_TYPE" />
                    </label>
                    <div className="" style={{ width: 150 }}>
                      <div className="input-group input-group-lg input-group-solid">
                        <Select
                          className={`w-100 h-auto interpiter_menu`}
                          name="userType"
                          value={userTypes.filter((e) => e.value == formik.values.userType)}
                          onChange={(e) => {
                            formik.setFieldValue('userType', e.value);
                            changeUserSelect();
                          }}
                          isOptionDisabled={(option) => option.disabled}
                          options={userTypes}
                        />

                        {/* <select
                          className={`form-control form-control-solid h-auto interpiter_menu`}
                          name="userType"
                          onChangeCapture={changeUserSelect}
                          {...formik.getFieldProps('userType')}
                        >
                          <option value="" disabled>
                            {intl.formatMessage({
                              id: 'DASHBOARD_SELECT_USER_TYPE'
                            })}
                          </option>
                          <option key={1} value={1}>
                            {intl.formatMessage({
                              id: 'DASHBOARD.USER'
                            })}
                          </option>
                          <option key={2} value={2}>
                            {intl.formatMessage({
                              id: 'DASHBOARD.INTERPRATOR'
                            })}
                          </option>
                        </select> */}
                      </div>
                    </div>
                  </div>

                  {formik.values.userType == 1 ? (
                    <div className="form-group row ml-8">
                      <label className="ml-3 mr-4 col-form-label">
                        <FormattedMessage id="DASHBOARD.USER" />
                      </label>
                      <div className="" style={{ width: 300 }}>
                        <div className="input-group input-group-lg input-group-solid">
                          <Select
                            className={`w-100 h-auto interpiter_menu`}
                            name="user"
                            value={userOption.filter((e) => e.value == formik.values.user)}
                            onChange={(e) => {
                              formik.setFieldValue('user', e.value);
                              getAdminUserAppointmentById(e.value);
                            }}
                            options={userOption}
                            isSearchable
                          />

                          {/* <select
                            className={`form-control form-control-solid h-auto`}
                            name="user"
                            onChangeCapture={(e) => getAdminUserAppointmentById(e.target.value)}
                            {...formik.getFieldProps('user')}
                          >
                            <option value="" disabled>
                              {intl.formatMessage({
                                id: 'DASHBOARD_SELECT_USER'
                              })}
                            </option>
                            {users.map((user) => {
                              return (
                                <option key={user.id} value={user.id}>
                                  {user.firstName} {user.lastName}
                                </option>
                              );
                            })}
                          </select> */}
                        </div>
                      </div>
                    </div>
                  ) : null}

                  {formik.values.userType == 2 ? (
                    <div className="form-group row ml-5">
                      <label className="ml-3 mr-4 col-form-label">
                        <FormattedMessage id="DASHBOARD.INTERPRATOR" />
                      </label>
                      <div className="" style={{ width: 300 }}>
                        <div className="input-group input-group-lg input-group-solid">
                          <Select
                            className={`w-100 h-auto interpiter_menu`}
                            name="interpratorId"
                            value={interpreterOption.filter(
                              (e) => e.value == formik.values.interpratorId
                            )}
                            onChange={(e) => {
                              formik.setFieldValue('interpratorId', e.value);
                              getAdminInterAppointmentById(e.value);
                            }}
                            options={interpreterOption}
                            isSearchable
                          />

                          {/* <select
                            className={`form-control form-control-solid h-auto`}
                            name="interpratorId"
                            onChangeCapture={(e) => getAdminInterAppointmentById(e.target.value)}
                            {...formik.getFieldProps('interpratorId')}
                          >
                            <option value="" disabled>
                              {intl.formatMessage({
                                id: 'DASHBOARD_SELECT_INTERPRATOR'
                              })}
                            </option>
                            {interprator.map((user) => {
                              return (
                                <option key={user.id} value={user.id}>
                                  {user.firstName} {user.lastName}
                                </option>
                              );
                            })}
                          </select>
                        */}
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {formik.values.userType !== '' ? (
                    <span
                      onClick={removeFilter}
                      className="ml-8"
                      style={{ lineHeight: 3, cursor: 'pointer' }}
                    >
                      {' '}
                      <FormattedMessage id="DASHBOARD.REMOVE_FILTER" />{' '}
                    </span>
                  ) : null}
                </div>
              ) : null}

              {/* <div style={{ position: 'absolute', right: '15px' }}>
              <div className="form-group row">
                {isAdmin() || isUser() ? (
                  <button
                    type="button"
                    onClick={createappointment}
                    style={{ marginRight: '15px', background: '#4fa4fb', color: '#fff' }}
                    className="btn btn-light btn-elevate"
                  >
                    <SVG src={toAbsoluteUrl('/media/svg/icons/Navigation/Plus.svg')} />
                    <FormattedMessage id="DASHBOARD.ADD_APPOINTMENT" />
                  </button>
                ) : null}

                {isAdmin() || isInterpreter() ? (
                  <button
                    type="button"
                    onClick={createschedule}
                    style={{ marginRight: '15px', background: '#c9f7f5', color: '#000' }}
                    className="btn btn-light btn-elevate"
                  >
                    <SVG src={toAbsoluteUrl('/media/svg/icons/Navigation/Plus.svg')} />
                    <FormattedMessage id="DASHBOARD.ADD_SCHEDULE" />
                  </button>
                ) : null}
              </div>
            </div> */}
            </div>
            {(isInterpreter() && !acceptPopup && modalShow) ||
            (adminType == 2 && isAdmin() && modalShow && !acceptPopup) ||
            (isAdmin() && modalShow && !acceptPopup && externalschbtn) ? (
              <SchedulePopup
                modalShow={modalShow}
                scheduleobj={scheduleobj}
                onModelHide={() => onModelHide(currentView)}
              ></SchedulePopup>
            ) : null}
            {(isUser() && modalShow) ||
            (adminType == 1 && isAdmin() && modalShow) ||
            (isAdmin() && modalShow && externalaptbtn) ? (
              <ApointmentPopup
                modalShow={modalShow}
                apointmentModel={apointmentModel}
                onModelHide={() => onModelHide(currentView)}
              ></ApointmentPopup>
            ) : null}
            {(isInterpreter() && acceptPopup && modalShow) ||
            (adminType == 2 && isAdmin() && modalShow && acceptPopup) ? (
              <AcceptPopup
                modalShow={modalShow}
                apointmentModel={apointmentModel}
                onModelHide={() => onModelHide(currentView)}
              ></AcceptPopup>
            ) : null}

            <Calendar
              selectable
              localizer={localizer}
              events={event}
              startAccessor="start"
              views={['week', 'day', 'month']}
              endAccessor="end"
              defaultView="week"
              formats={formats}
              style={
                isAdmin()
                  ? { height: '69vh', width: '100%' }
                  : { height: '69vh', width: '100%', marginTop: '50px' }
              }
              onSelectEvent={onEventSelect}
              onSelectSlot={(event) => handleSelect(event)}
              dayLayoutAlgorithm="no-overlap"
              messages={{
                next: intl.formatMessage({
                  id: 'NEXT'
                }),
                previous: intl.formatMessage({
                  id: 'PREVIOUS'
                }),
                today: intl.formatMessage({
                  id: 'TODAY'
                }),
                work_week: intl.formatMessage({
                  id: 'WEEK'
                }),
                day: intl.formatMessage({
                  id: 'DAY'
                }),
                month: intl.formatMessage({
                  id: 'MONTH'
                })
              }}
              onView={(view) => {
                setCurrentView(view);
                onModelHide(view);
              }}
              eventPropGetter={(event, start, end, isSelected) => {
                let newStyle = {
                  backgroundColor: 'lightblue',
                  color: 'black',
                  borderRadius: '0px',
                  border: 'none'
                };

                if (event.status == 2) {
                  newStyle.backgroundColor = '#c5dd0d';
                }

                if (event.status == 1) {
                  newStyle.backgroundColor = 'lightgreen';
                }

                if (event.status == 0) {
                  newStyle.backgroundColor = 'red';
                }

                if(event.appointmentobj != undefined) {
                  if (event.appointmentobj.is_deleted) {
                    newStyle.backgroundColor = 'lightgray';
                  }

                }

                if (
                  (isInterpreter() && event.type == 'appointment') ||
                  (isAdmin() && event.type == 'appointment')
                ) {
                  if (event.appointmentobj.is_deleted ) newStyle.backgroundColor = 'lightgray';
                }

                if (isUser() ) {
                  if (event.type == 'Interprator' && currentView == 'month')
                    newStyle.backgroundColor = 'transparent';
                  //newStyle.width="35px"
                }

                return {
                  className: '',
                  style: newStyle
                };
              }}
              min={
                new Date(
                  today.getFullYear(),
                  today.getMonth(),
                  today.getDate(),
                  props.intl.messages['DASHBOARD_CALANDER_STRAT_TIME']
                )
              }
              max={
                new Date(
                  today.getFullYear(),
                  today.getMonth(),
                  today.getDate(),
                  props.intl.messages['DASHBOARD_CALANDER_END_TIME']
                )
              }
              // weekends={false}
              // hiddenDays={[6, 0]}
            />
          </div>
        ) : (
          <div className="confiormation-center">
            <span className="confiormation-text">
              {' '}
              <FormattedMessage id="DASHBOARD.ACCOUNT_NOT_ACTIVE" />
            </span>
          </div>
        )}
      </div>
    </div>
  );
}

const mapDispatchToEvents = (dispatch) => {
  return {
    commonActions: bindActionCreators(common.actions, dispatch)
  };
};

export default injectIntl(connect(null, mapDispatchToEvents)(DashboardPage));
