import { Repository } from '../../servics/httpsServics';

const controller = '/singlecall';


export function getVideoAppointment(videoCode) {
  return Repository.get(controller + '/get-video-appointment/' + videoCode);
}

export function endUserCall(data) {
  return Repository.post('/user-single-calls/end', data);
}
