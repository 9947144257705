import React from 'react';

function UserCallComponent(props) {
  return (
    <div className="video_call_main_wrapper">
      <div className="video_call_inner vc_waiting_user">
        <div className="vc_waiting_user_wrap">
          <div className="vc_waiting_animation">
            <div className="vc_waiting_main_circle"></div>
            <div className="vc_circle_wave wave_1"></div>
            <div className="vc_circle_wave wave_2"></div>
            <div className="vc_circle_wave wave_3"></div>
            <div className="vc_circle_wave wave_4"></div>
          </div>
          <div className="vc_waiting_text">
            {props.current_state === 'connecting' ? <p>In connessione</p> : null}
            {props.current_state === 'waiting' ? (
              props.interpreter_available === 0 ? (
                <p>Nessun interprete disponibile</p>
              ) : (
                <p>
                  In attesa dell'interprete...<br></br>
                  {props.waiting > 0 ? <>Utenti in attesa: {props.waiting}</> : null}
                </p>
              )
            ) : null}
            {/* {props.current_state === 'waiting' ? <p>Waiting for interpreters...</p> : null}
            {props.current_state === 'waiting' && props.waiting > 0 ? (
              <p>Users Waiting: {props.waiting}</p>
            ) : null} */}
            {props.current_state === 'starting_call' ? <p>Tocca a te!</p> : null}
            {props.current_state === 'starting_call' ? <p>Inizio Chiamata...</p> : null}
          </div>
          <div className="vc_waiting_btn global_btn">
            {props.current_state === 'waiting' ? (
              <button onClick={props.closeRoom}>Termina Chiamata</button>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserCallComponent;
